
.react-datepicker{
    padding:24px;
    border-radius: 8px !important;
    border: 1px solid #DDD !important;
    background: #FFF !important;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05) !important;
}
.react-datepicker__header{
    padding: 0 !important;
    border-bottom: none !important;
    background:white !important;
    z-index: 1000 !important;
    margin-bottom: 8.5px !important;

}

.react-datepicker__day--today,
.react-datepicker__day--keyboard-selected
{
    color:#A155B9 !important;
    background:white !important;
}
.react-datepicker__day--selected {
    background:#A155B9 !important;
    color:white !important;
    border-radius: 100% !important;
}

.react-datepicker__day{
    width:32px !important;
    height:32px !important;
    margin:0 !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: 'TBCXRegular';

}

.react-datepicker__day:hover{
    border: 1px solid #A155B9;
    border-radius: 100% !important;
    color: #A155B9 !important;
    background:white !important;
}
.react-datepicker__month{
    margin: 0 !important;
}

.react-datepicker__day--outside-month{
    color: #A7A7A7 !important;
}
.react-datepicker__day-name{
    /* font-weight: 500 !important; */
    font-family: "TBCXBold" !important;
}


.react-datepicker__month-text--today{
    background: white !important;
}
.react-datepicker__month-text--keyboard-selected{
    background:transparent !important;
}


.react-datepicker__month-text--keyboard-selected:hover{
    border:1px solid #A155B9;
    background: transparent !important;
}

.react-datepicker__month-text{
    border:1px solid transparent !important;

    padding: 6px 30px !important;
    margin: 0 !important;
    box-sizing:border-box !important;
    width: 90px !important;
    height:32px !important;
    display:inline-flex !important;
    align-items:center !important;
    justify-content:center !important;
    border-radius:56px !important;
    font-family: "TBCXNormal";
    font-size: 15px;
    transition: all 0.3s ease;

}
.react-datepicker__month-text:hover{
    border:1px solid #A155B9 !important;
    background: transparent !important;
}

.react-datepicker__monthPicker{
    padding: 16px 0 !important;
}

.react-datepicker__month-text--selected,.react-datepicker__month-text--selected:hover{
    background: #A155B9 !important;
    color:white !important;
}




.react-datepicker__month-wrapper:not(:last-child) {
    /* Your styles here */
    margin-bottom:24px !important;
}