@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  .vacancy-description li,
  .vacancy-description ul,
  .vacancy-description ol,
  .vacancy-description p {
    list-style: revert;
    margin: revert;
    padding: revert;
  }
}
image {
  pointer-events: none;
}
@font-face {
  font-family: "TBCXRegular";
  font-style: normal;
  src: url("/fonts/tbc/TBCXRegular.eot");
  src:
    url("/fonts/tbc/TBCXRegular.eot") format("embedded-opentype"),
    url("/fonts/tbc/TBCXRegular.ttf") format("truetype"),
    url("/fonts/tbc/TBCXRegular.woff") format("woff"),
    url("/fonts/tbc/TBCXRegular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "TBCXMedium";
  font-style: normal;
  src: url("/fonts/tbc/TBCXMedium.eot");
  src:
    url("/fonts/tbc/TBCXMedium.eot") format("embedded-opentype"),
    url("/fonts/tbc/TBCXMedium.ttf") format("truetype"),
    url("/fonts/tbc/TBCXMedium.woff") format("woff"),
    url("/fonts/tbc/TBCXMedium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "TBCXBold";
  font-style: normal;
  src:
    url("/fonts/tbc/TBCXBold.eot") format("embedded-opentype"),
    url("/fonts/tbc/TBCXBold.ttf") format("truetype"),
    url("/fonts/tbc/TBCXBold.woff") format("woff"),
    url("/fonts/tbc/TBCXBold.woff2") format("woff2");
  font-display: swap;
}

input {
  @apply focus:outline-none;
}

.swiper-button-disabled,
.swiper-button-disabled:hover {
  @apply border-neutral-20 bg-neutral-20 fill-secondary-100 !important;
}

/* WebKit-based browsers (Safari, Chrome) */
/* *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer;
}

*::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 8px;
  border-right: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
} */

/* Add to your global CSS */
html {
  scrollbar-gutter: stable;
  width: 100vw;
  overflow-x: hidden;
}

.vacancy-list-scrollbar::-webkit-scrollbar {
  width: 16px;
  color: red;
}

.vacancy-list-scrollbar::-webkit-scrollbar-thumb {
  border-right: 4px solid white;
  border-left: 4px solid white;
  border-radius: 7px !important;
}
.vacancy-list-scrollbar::-webkit-scrollbar-track {
  background-color: white;
}

/* MIU slider padding */
.MuiSlider-root {
  padding: 0 !important;
  height: 2px !important;
}

.searchspinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #000;
  flex-shrink: 0;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.people-card-filters {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 52.21%, rgba(0, 0, 0, 0.2) 73.83%, rgba(0, 0, 0, 0.523) 100%);
}

.cv-input-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #333 #f0f0f0; /* Color of the scrollbar thumb and track */
}

.cv-input-container::-webkit-scrollbar {
  width: 8px; /* For Chrome, Safari, and Opera */
}

.cv-input-container::-webkit-scrollbar-thumb {
  background-color: #333; /* Color of the scrollbar thumb */
}

.cv-input-container::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Color of the scrollbar track */
}

/* Remove top and bottom arrwos from number input */
/* Remove the default arrow styling */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Set the input field to a fixed width */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.rounded-tooltip {
  border-radius: 10px !important; /* Adjust this value to your preference */
  width: 296px;
}

.custom-underline {
  text-decoration-skip-ink: none;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


li {
  list-style: none;
}

.input-error input {
  border-color: red !important;
}

li.break {
  pointer-events: none;
}

/* PAGINATION */ /* PAGINATION */
/* PAGINATION */ /* PAGINATION */
/* PAGINATION */ /* PAGINATION */
/* li.break::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  cursor: default;
} */

#cv-scrollbar::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
  background-color: white !important;
}

#cv-scrollbar::-webkit-scrollbar-track {
  background-color: white !important;
  border-left: 1px solid #dddddd; /* Left black border */
  border-right: 1px solid #dddddd; /* Right black border */
}
#cv-scrollbar::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

#cv-scrollbar::-webkit-scrollbar-thumb {
  margin: 1px !important;
  background-color: #d9d9d9; /* Thumb color (black) */
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  width: 4px;
}
#cv-scrollbar::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: #cfcfcf; /* Thumb color (black) */
}

/* .pdf-container {
  width: 816px; 
  height: 1056px; 
  overflow: hidden;
  margin: 0 auto;
  transform: scale(1); 
  transform-origin: top left;
} */

.react-pdf__Page {
  max-width: fit-content !important;
}
.react-pdf__Page__canvas {
  height: auto !important;
  width: auto !important;
  max-width: 100% !important;
  max-height: calc(100vh - 74px) !important;
} 
/* FULLSCREEN */
.resume-preview .react-pdf__Page__canvas{
  max-height: none !important;
}

/* ANIMATE */
@keyframes popUp {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes popOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(1.15);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.animate-pop-up {
  animation: popUp 0.35s ease-out forwards;
}
.animate-pop-out {
  animation: popOut 0.25s ease-in forwards;
}



.react-pdf__Document.rendering-document{
  /* position: absolute !important; */
  display: none;
  /* opacity: 0; */
}

.react-pdf__Document.previous-document{
  /* opacity: 0.5; */
}


.react-pdf__Document.rendering-document .react-pdf__Page{
  box-shadow: none;
}

.vacancy-description a{
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}