.react-paginate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.react-paginate .selected {
  background: #a155b9;
  color: white;
}

.react-paginate li {
  border-radius: 8px;
}

.react-paginate li a {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
